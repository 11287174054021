:root {
  --gradient: linear-gradient(129deg, #11d6bb, #000);
  --gradient-light: linear-gradient(lightblue, blanchedalmond);
  --gradient-dark: linear-gradient(teal, #343a40);
  --gradient-darkPrimary: linear-gradient(45deg, teal, var(--primary));
  --gradient-darkIndiago: linear-gradient(var(--dark), var(--lightIndiago));
  --gradient-blue: linear-gradient(299deg, #00baff, #000000);
  --blue: #556ee6;
  --indigo: #564ab1;
  --lightIndiago: #2a2f4d;
  --darkIndiago: #6b6f81;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #f46a6a;
  --orange: #f1734f;
  --yellow: #f1b44c;
  --green: #34c38f;
  --olive-green: #1c766a;
  --teal: #050505;
  --cyan: #50a5f1;
  --white: #fff;
  --gray: #74788d;
  --gray-dark: #343a40;
  --primary: #556ee6;
  --secondary: #74788d;
  --success: #34c38f;
  --info: #50a5f1;
  --warning: #f1b44c;
  --danger: #f46a6a;
  --light: #eff2f7;
  --dark: #343a40;
  --pink: #e83e8c;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Nunito", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 1rem;
}
*::-webkit-scrollbar {
  width: 3px;
}
*::-webkit-scrollbar-track {
  /* box-shadow: 0.1rem 0.1rem 0.3rem #ddd; */
  border-radius: 1rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
