.App {
  text-align: center;
}

.App-link {
  color: #61dafb;
}

.navActive {
  border-radius: 1rem;
  box-shadow: 0.1rem 0.1rem 0.3rem #1c98b6;
}

.recordAnimation {
  border-radius: 1rem;
  animation: record-animation infinite 1s linear;
}

.fullcenter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.light {
  background: var(--light);
}
.dark {
  background: var(--dark);
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-light {
  color: var(--light);
}
.text-dark {
  color: var(--dark);
}
.text-primary {
  color: var(--primary);
}
.text-danger {
  color: var(--danger);
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes record-animation {
  20% {
    color: var(--primary);
    transform: scale(0.8);
  }
  40% {
    color: var(--danger);
    transform: scale(0.9);
  }
  60% {
    color: var(--success);
    transform: scale(1);
  }
  80% {
    transform: scale(0.9);
    color: var(--warning);
  }
}
